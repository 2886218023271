import styles from "./Partners.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import transmasholdingSvg from "../../../../assets/svg/partners_transmasholding.svg";
import ministryOfEnergSvg from "../../../../assets/svg/partners_ministryOfEnergy.svg";
import locotechSvg from "../../../../assets/svg/partners_locotech.svg";
import tractorFactoriesSvg from "../../../../assets/svg/partners_tractorFactories.svg";
import kskSvg from "../../../../assets/svg/partners_ksk.svg";
import concreteElementsSvg from "../../../../assets/svg/partners_concreteElements.svg";
import fasSvg from "../../../../assets/svg/partners_fas.svg";
import npMarketCouncilSvg from "../../../../assets/svg/partners_npMarketCouncil.svg";
import energyprofAuditSvg from "../../../../assets/svg/partners_energyprofAudit.svg";
import atsSvg from "../../../../assets/svg/partners_ats.svg";
import "./styles.scss";
import "swiper/css/scrollbar";
import "swiper/css";

const Partners = () => {
  const partners = [
    transmasholdingSvg,
    ministryOfEnergSvg,
    locotechSvg,
    tractorFactoriesSvg,
    kskSvg,
    concreteElementsSvg,
    fasSvg,
    npMarketCouncilSvg,
    energyprofAuditSvg,
    atsSvg,
  ];

  return (
    <div className={styles.partners} id="partners">
      <div className={`${styles.title} heading large`}>Наши партнеры</div>
      <div className={styles.slider}>
        <Swiper
          spaceBetween={4}
          slidesPerView={3.1}
          breakpoints={{
            1536: {
              slidesPerView: 4.1,
              spaceBetween: 20,
            },
            1366: {
              slidesPerView: 3.6,
              spaceBetween: 20,
            },
            1136: {
              slidesPerView: 2.7,
              spaceBetween: 20,
            },
            962: {
              slidesPerView: 2.3,
              spaceBetween: 20,
            },
          }}
          scrollbar={{
            hide: true,
            draggable: true,
          }}
          modules={[Scrollbar]}
          className="partnersSwiper"
        >
          {partners.map((card, idx) => (
            <SwiperSlide key={idx}>
              <div className={styles.card}>
                <img src={card} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Partners;
