import styles from "./ButtonSmall.module.scss";
import { ReactComponent as Arrow } from "../../../../assets/svg/arrow.svg";

const ButtonSmall = ({ isActive }) => {
  return (
    <div className={`${styles.button} ${isActive ? `${styles.active}` : ""}`}>
      <div className={styles.arrow}>
        <Arrow />
      </div>
    </div>
  );
};

export default ButtonSmall;
