import styles from "./Media.module.scss";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../../../api/api";
import { formatDate } from "../../../../utils/utils";
import ButtonBig from "../../../Ui/Buttons/ButtonBig/ButtonBig";
import MediaCard from "../../../Ui/MediaCard/MediaCard";

const Media = () => {
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    async function getNewsList() {
      const data = await api.getNewsList();
      if (!data.error) {
        setNewsList(data);
      }
    }
    getNewsList();
  }, []);

  return (
    <div className={styles.media}>
      <div className={styles.header}>
        <div className="heading large">Медиа</div>
        <div className={styles.button}>
          <Link to="/media">
            <ButtonBig text="ЧИТАТЬ ВСЁ" />
          </Link>
        </div>
      </div>
      <div className={styles.content}>
        {newsList.slice(0, 3).map((news, idx) => {
          return (
            <MediaCard
              date={formatDate(news?.attributes?.date)}
              title={news.attributes?.title}
              description={news?.attributes?.description}
              img={`https://strapi.backspark.ru${news?.attributes?.previewImg?.data?.attributes?.url}`}
              newsId={news?.id}
              key={idx}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Media;
