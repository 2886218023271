import styles from "./Header.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../../assets/svg/logo.svg";

const Header = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  if (mobileMenuActive) {
    document.querySelector("body").style.overflow = "hidden";
  } else {
    document.querySelector("body").style.overflow = "auto";
  }

  function awaitNewsApi(el) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: "auto", block: "start" });
    }, 500);
  }

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.logo}>
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className={`${styles.content} text regular`}>
          <div className={styles.item}>
            <HashLink smooth to="/#group">
              Группа компаний
            </HashLink>
          </div>
          <div className={styles.item}>
            <HashLink smooth to="/#partners">
              Наши партнеры
            </HashLink>
          </div>
          <div className={styles.item}>
            <HashLink smooth to="/#about">
              О компаниях
            </HashLink>
          </div>
          <div className={styles.item}>
            <HashLink to="/#solutions" scroll={(el) => awaitNewsApi(el)}>
              Решения для бизнеса
            </HashLink>
          </div>
          <div className={styles.item}>
            <Link to="/media">Медиа</Link>
          </div>
        </div>
        <div className={`${styles.phone} text regular`}>
          <a href="tel:+74951034572">+7 (495) 103-45-72</a>
        </div>
        <div
          className={
            mobileMenuActive
              ? `${styles.menuIcon} ${styles.active}`
              : `${styles.menuIcon}`
          }
          onClick={() => setMobileMenuActive(!mobileMenuActive)}
        >
          <span className={`${styles.barItem} ${styles.barTop}`}></span>
          <span className={`${styles.barItem} ${styles.barMid}`}></span>
          <span className={`${styles.barItem} ${styles.barBot}`}></span>
        </div>
      </div>
      <div
        className={
          mobileMenuActive
            ? `${styles.mobileMenu} ${styles.active}`
            : `${styles.mobileMenu}`
        }
      >
        <div className={`${styles.mobileContent} heading medium`}>
          <div
            className={styles.mobileContent__item}
            onClick={(e) => setMobileMenuActive(false)}
          >
            <HashLink smooth to="/#group">
              Группа компаний
            </HashLink>
          </div>
          <div
            className={styles.mobileContent__item}
            onClick={() => setMobileMenuActive(false)}
          >
            <HashLink smooth to="/#partners">
              Наши партнеры
            </HashLink>
          </div>
          <div
            className={styles.mobileContent__item}
            onClick={() => setMobileMenuActive(false)}
          >
            <HashLink smooth to="/#about">
              О компаниях
            </HashLink>
          </div>
          <div
            className={styles.mobileContent__item}
            onClick={() => setMobileMenuActive(false)}
          >
            <HashLink to="/#solutions" scroll={(el) => awaitNewsApi(el)}>
              Решения для бизнеса
            </HashLink>
          </div>
          <div
            className={styles.mobileContent__item}
            onClick={() => setMobileMenuActive(false)}
          >
            <Link to="/media">Медиа</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
