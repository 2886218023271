import styles from "./Hero.module.scss";
import { useState, useRef, useEffect } from "react";
import video from "../../../../assets/videos/heroVideo.mp4";

const Hero = () => {
  const [videoHeight, setVideoHeight] = useState(null);
  const refDescription = useRef();
  const refHero = useRef();

  function editVideoHeight() {
    const heightHero = refHero.current?.getBoundingClientRect().height;
    const heightDescription =
      refDescription.current?.getBoundingClientRect().height;
    if (window.innerWidth <= 962) {
      setVideoHeight(heightHero - heightDescription - 28);
    } else {
      setVideoHeight(null);
    }
  }

  window.onresize = () => {
    editVideoHeight();
  };

  useEffect(() => {
    editVideoHeight();
  }, []);

  return (
    <div className={styles.hero} ref={refHero}>
      <div className={styles.video} style={{ height: videoHeight }}>
        <video src={video} autoPlay muted loop />
      </div>
      <div className={styles.info} ref={refDescription}>
        <div className={`${styles.content} text regular light`}>
          ГК Трансэнергопром – энергетический холдинг, осуществляющий
          деятельность в 26 субъектах России
        </div>
      </div>
    </div>
  );
};

export default Hero;
