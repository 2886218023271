import styles from "./ButtonBig.module.scss";
import { ReactComponent as Arrow } from "../../../../assets/svg/arrow.svg";

const ButtonBig = ({ text, active, onClick }) => {
  return (
    <div
      className={`${styles.button} ${active ? `${styles.active}` : ""}`}
      onClick={onClick}
    >
      <div className={`${styles.text} text small`}>{text}</div>
      <div className={styles.arrow}>
        <Arrow />
      </div>
    </div>
  );
};

export default ButtonBig;
