export function formatDate(data) {
  if (data) {
    let date = data.split("T");
    date = date[0].split("-").reverse();
    switch (date[1]) {
      case "01":
        date[1] = "января";
        break;
      case "02":
        date[1] = "февраля";
        break;
      case "03":
        date[1] = "марта";
        break;
      case "04":
        date[1] = "апреля";
        break;
      case "05":
        date[1] = "мая";
        break;
      case "06":
        date[1] = "июня";
        break;
      case "07":
        date[1] = "июля";
        break;
      case "08":
        date[1] = "августа";
        break;
      case "09":
        date[1] = "сентября";
        break;
      case "10":
        date[1] = "октября";
        break;
      case "11":
        date[1] = "ноября";
        break;
      case "12":
        date[1] = "декабря";
        break;
      default:
        date[1] = " ";
    }
    date = date.join(" ");
    return date;
  }
}

export function sliceText(text, numberOfCharacters) {
  let string = text;
  if (text.length > numberOfCharacters) {
    string = text.slice(0, numberOfCharacters);
    string += "...";
  }
  return string;
}
