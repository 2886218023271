import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Main from "./components/Pages/Main/Main";
import Footer from "./components/Footer/Footer";
import AllNews from "./components/Pages/AllNews/AllNews";
import News from "./components/Pages/News/News";

function App() {
  return (
    <div className="App">
      <div className="wrap">
        <BrowserRouter>
          <Header />
          <div className="container">
            <Routes>
              <Route path="*" element={<Main />} />
              <Route path="media" element={<AllNews />} />
              <Route path="media/:id" element={<News />} />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
