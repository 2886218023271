import styles from "./About.module.scss";
import Card from "./Card/Card";
import aboutImg from "../../../../assets/images/about_img1.jpg";
import transenergopromLogo from "../../../../assets/svg/transenergoprom_logo.svg";
import generationLogo from "../../../../assets/svg/generation_logo.svg";
import siriusLogo from "../../../../assets/svg/sirius_logo.svg";
import energocapitalLogo from "../../../../assets/svg/energocapital_logo.svg";
import chskLogo from "../../../../assets/svg/chsk_logo.svg";
import energopromLogo from "../../../../assets/svg/energoprom_logo.svg";

const About = () => {
  return (
    <div className={styles.about} id="about">
      <div className={styles.mainSection}>
        <div className={styles.image}>
          <img src={aboutImg} alt="" />
        </div>
        <div className={styles.info}>
          <div className={`${styles.title} text medium`}>О компаниях</div>
          <div className={`${styles.description} heading medium light`}>
            ГК ТЭП – многопрофильный холдинг, в нашем управлении 6
            энергетических компаний
          </div>
        </div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.cardsSection}>
          <div className={styles.leftCards}>
            <div className={styles.card1}>
              <Card
                title="Энергопром"
                description="Строительство, ремонт и обслуживание энергетической инфраструктуры EPC-EPCM-PCM контракты"
                link="https://e-nergoprom.ru/"
                logo={energopromLogo}
              />
            </div>
            <div className={styles.card2}>
              <Card
                title="ЭНЕРГОКАПИТАЛ-СБЫТ"
                description="Энергоснабжение объектов торговой отрасли"
                logo={energocapitalLogo}
                link="http://enc-sbyt.ru/"
              />
            </div>
          </div>
          <div className={styles.rightCards}>
            <div className={styles.rightCards__content}>
              <div className={styles.colLeft}>
                <div className={styles.card3}>
                  <Card
                    title="ЧЭСК"
                    description="Гарантирующий поставщик электроэнергии в Республике Чувашия"
                    logo={chskLogo}
                    link="https://ch-sk.ru/"
                  />
                </div>
                <div className={styles.card4}>
                  <Card
                    title="Сириус"
                    description="Единая теплоснабжающая организация"
                    logo={siriusLogo}
                    link="https://сириус161.рф/"
                  />
                </div>
              </div>
              <div className={styles.colRight}>
                <div className={styles.card5}>
                  <Card
                    title="Трансэнергопром"
                    description="Энергоснабжение крупнейших промышленных предприятий России"
                    logo={transenergopromLogo}
                    link="https://transenprom.ru/"
                  />
                </div>
                <div className={styles.card6}>
                  <Card
                    title="ТЭП-генерация"
                    description="Производственное предприятие по выпуску объектов малой генерации"
                    logo={generationLogo}
                    disableCursorPointer={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mobileCards}>
        <div className={styles.mobileCard}>
          <Card
            title="ЧЭСК"
            description="Гарантирующий поставщик электроэнергии в Республике Чувашия"
            logo={chskLogo}
            link="https://ch-sk.ru/"
          />
        </div>
        <div className={styles.mobileCard}>
          <Card
            title="Трансэнергопром"
            description="Энергоснабжение крупнейших промышленных предприятий России"
            logo={transenergopromLogo}
            link="https://transenprom.ru/"
          />
        </div>
        <div className={styles.mobileCard}>
          <Card
            title="Энергопром"
            description="Строительство, ремонт и обслуживание энергетической инфраструктуры EPC-EPCM-PCM контракты"
            link="https://e-nergoprom.ru/"
            logo={energopromLogo}
          />
        </div>
        <div className={styles.mobileCard}>
          <Card
            title="ЭНЕРГОКАПИТАЛ-СБЫТ"
            description="Энергоснабжение объектов торговой отрасли"
            logo={energocapitalLogo}
            link="http://enc-sbyt.ru/"
          />
        </div>
        <div className={styles.mobileCard}>
          <Card
            title="Сириус"
            description="Единая теплоснабжающая организация"
            logo={siriusLogo}
            link="https://сириус161.рф/"
          />
        </div>
        <div className={styles.mobileCard}>
          <Card
            title="ТЭП-генерация"
            description="Производственное предприятие по выпуску объектов малой генерации"
            logo={generationLogo}
            disableCursorPointer={true}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
