import styles from "./Card.module.scss";
import { useState } from "react";
import ButtonSmall from "../../../../Ui/Buttons/ButtonSmall/ButtonSmall";

const Card = ({ logo, title, description, link, disableCursorPointer }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className={
        disableCursorPointer
          ? `${styles.card} ${styles.disableCursorPointer}`
          : `${styles.card}`
      }
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={logo} alt="" />
          </div>
          <div className={styles.info}>
            <div className={`${styles.title} text large`}>{title}</div>
            <div className={`${styles.description} text regular light`}>
              {description}
            </div>
          </div>
        </div>
        <ButtonSmall
          isActive={isActive ? (disableCursorPointer ? false : true) : false}
        />
      </a>
    </div>
  );
};

export default Card;
