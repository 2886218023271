import styles from "./AllNews.module.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "../../../api/api";
import { formatDate } from "../../../utils/utils";
import ButtonBig from "../../Ui/Buttons/ButtonBig/ButtonBig";
import MediaCard from "../../Ui/MediaCard/MediaCard";
import mediaImg from "../../../assets/images/media.webp";

const AllNews = () => {
  const settings = {
    visibleNewsDefaultCount: 5,
  };
  const { pathname } = useLocation();

  const [newsList, setNewsList] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [visibleNewsCount, setVisibleNewsList] = useState(
    settings.visibleNewsDefaultCount
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    async function getNewsList() {
      const data = await api.getNewsList();
      if (!data.error) {
        setNewsList(data);
      }
    }
    getNewsList();
  }, []);

  function clickCategory(category) {
    if (currentCategory !== category) {
      setCurrentCategory(category);
      setVisibleNewsList(settings.visibleNewsDefaultCount);
    }
  }

  return (
    <div className={styles.allNews}>
      <div className={styles.headband}>
        <img src={mediaImg} alt="" />
      </div>
      <div className={styles.content}>
        <div className={`${styles.buttons} text  small`}>
          <div
            className={
              currentCategory === ""
                ? `${styles.button} ${styles.active}`
                : `${styles.button}`
            }
            onClick={() => clickCategory("")}
          >
            ВСЕ
          </div>
          <div
            className={
              currentCategory === "новости"
                ? `${styles.button} ${styles.active}`
                : `${styles.button}`
            }
            onClick={() => clickCategory("новости")}
          >
            НОВОСТИ
          </div>
          {/*<div className={styles.button}>РЕПОРТАЖИ</div>*/}
          <div
            className={
              currentCategory === "медиапроекты"
                ? `${styles.button} ${styles.active}`
                : `${styles.button}`
            }
            onClick={() => clickCategory("медиапроекты")}
          >
            МЕДИАПРОЕКТЫ
          </div>
        </div>
        <div className={styles.newsList}>
          {newsList
            .filter((news) => {
              if (currentCategory === "") {
                return news;
              } else {
                return news.attributes.tag === currentCategory;
              }
            })
            .slice(0, visibleNewsCount)
            .map((news, idx) => {
              return (
                <MediaCard
                  date={formatDate(news?.attributes?.date)}
                  title={news?.attributes?.title}
                  description={news?.attributes?.description}
                  img={`https://strapi.backspark.ru${news?.attributes?.previewImg?.data?.attributes?.url}`}
                  newsId={news.id}
                  key={idx}
                />
              );
            })}
        </div>
        <div className={styles.load}>
          <ButtonBig
            text="ЕЩЁ"
            onClick={() => {
              setVisibleNewsList((prev) => prev + 5);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AllNews;
