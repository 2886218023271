import styles from "./Card.module.scss";

const Card = ({ title, description, img, hoverInfo }) => {
  return (
    <div
      className={img ? `${styles.card} ${styles.withImg}` : `${styles.card}`}
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className={styles.content}>
        <div className={styles.mainInfo}>
          <div className={`${styles.title} heading medium`}>{title}</div>
          <div className={`${styles.description} text large light`}>
            {description}
          </div>
        </div>
        <div className={`${styles.hoverInfo} text big`}>
          {hoverInfo?.map((paragraph, idx) => (
            <div className={styles.paragraph} key={idx}>
              {paragraph}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
