import styles from "./News.module.scss";
import React from "react";
import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import api from "../../../api/api";
import { formatDate } from "../../../utils/utils";
import ButtonBig from "../../Ui/Buttons/ButtonBig/ButtonBig";
import "./styles.scss";
import "swiper/css/scrollbar";
import "swiper/css";

const News = () => {
  const [newsList, setNewsList] = useState([]);
  const [currentNews, setCurrentNews] = useState({});
  const params = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    function searchCurrentNews(data, id) {
      setCurrentNews(data.find((news) => Number(news.id) === Number(id)));
    }
    async function getNews() {
      const data = await api.getNewsList();
      if (!data.error) {
        setNewsList(data);
        searchCurrentNews(data, params.id);
      }
    }
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchCurrentNews(newsList, params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  function searchCurrentNews(data, id) {
    setCurrentNews(data.find((news) => Number(news.id) === Number(id)));
  }

  return (
    <div className={styles.news}>
      <div className={styles.content}>
        <div className={`${styles.title} heading medium`}>
          {currentNews?.attributes?.title}
        </div>
        <div className={`${styles.data} text medium`}>
          {formatDate(currentNews?.attributes?.date)}
        </div>
        <div className={`${styles.info} text regular`}>
          <ReactMarkdown className={styles.reactMarkDown}>
            {currentNews?.attributes?.text}
          </ReactMarkdown>
        </div>
        {currentNews?.attributes?.galleryImg?.data?.length === 1 && (
          <div className={styles.image}>
            <img
              src={`https://strapi.backspark.ru${currentNews.attributes?.galleryImg.data[0].attributes.url}`}
              alt=""
            />
          </div>
        )}
        {currentNews?.attributes?.galleryImg?.data?.length > 1 && (
          <div className={styles.image}>
            <Swiper
              slidesPerView={1}
              scrollbar={{
                hide: true,
                draggable: true,
              }}
              modules={[Scrollbar]}
              className="newsSwiper"
            >
              {currentNews.attributes.galleryImg.data.map((img, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <div className={styles.image} key={idx}>
                      <img
                        src={`https://strapi.backspark.ru${img.attributes.url}`}
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </div>
      <div className={styles.allNews}>
        <div className={`${styles.header} text large`}>Все новости</div>
        <div className={styles.allNews__content}>
          {newsList.slice(0, 6).map((news, idx) => {
            return (
              <React.Fragment key={idx}>
                <Link to={`/media/${news.id}`}>
                  <div className={styles.item}>
                    <div className={`${styles.title} text regular`}>
                      {news.attributes.title}
                    </div>
                    <div className={`${styles.date} text small_medium`}>
                      {formatDate(news.attributes.date)}
                    </div>
                  </div>
                </Link>
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.button}>
          <Link to="/media">
            <ButtonBig text="ЧИТАТЬ ВСЁ" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default News;
