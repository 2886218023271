import styles from "./Main.module.scss";
import Hero from "./Hero/Hero";
import GroupOfCompanies from "./GroupOfCompanies/GroupOfCompanies";
import Map from "./Map/Map";
import Partners from "./Partners/Partners";
import About from "./About/About";
import Media from "./Media/Media";
import Solutions from "./Solutions/Solutions";

const Main = () => {
  return (
    <div className={styles.main}>
      <Hero />
      <GroupOfCompanies />
      <Map />
      <Partners />
      <About />
      <Media />
      <Solutions />
    </div>
  );
};

export default Main;
