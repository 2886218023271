import styles from "./MediaCard.module.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import ButtonSmall from "../../Ui/Buttons/ButtonSmall/ButtonSmall";

const MediaCard = ({ date, title, description, img, newsId }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <Link to={`/media/${newsId}`}>
        <div
          className={
            isActive
              ? `${styles.mediaCard} ${styles.active}`
              : `${styles.mediaCard}`
          }
          onMouseEnter={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
        >
          <div className={`${styles.date} text regular`}>{date}</div>
          <div className={styles.content}>
            <div className={styles.image}>
              <img src={img} alt="" />
            </div>
            <div className={styles.info}>
              <div className={styles.wrap}>
                <div className={`${styles.title} text medium`}>{title}</div>
                <div className={`${styles.description} text small_medium`}>
                  {description}
                </div>
              </div>
              <div className={styles.button}>
                <ButtonSmall isActive={isActive} />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default MediaCard;
