import axios from "axios";
import { urls } from "./urls";

class Api {
  async getNewsList() {
    try {
      const data = await axios.get(
        `${urls.mainURL}api/novostis?sort=date:desc&populate=*`
      );
      return data.data.data;
    } catch (error) {
      return { error: true };
    }
  }
}

const api = new Api();

export default api;
