import styles from "./Map.module.scss";
import map from "../../../../assets/images/map_desktop.webp";

const Map = () => {
  return (
    <div className={styles.map}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <img src={map} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Map;
