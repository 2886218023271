import styles from "./Solutions.module.scss";
import Card from "./Card/Card";
import img1 from "../../../../assets/images/solutions_img1.jpg";
import img2 from "../../../../assets/images/solutions_img2.jpg";
import img3 from "../../../../assets/images/solutions_img3.jpg";

const Solutions = () => {
  return (
    <div className={styles.solutions} id="solutions">
      <div className={`${styles.title} heading large`}>Решения для бизнеса</div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.cardLeft}>
            <Card
              title="Чистая энергия"
              description="Строительство и обслуживание электрозаправочных комплексов"
              img={img1}
              hoverInfo={[
                "Заправочные станции ГК ТЭП – многократно отработанное с инфраструктурной и архитектурной точки зрения инженерное решение. Мы одни из немногих в России строим «хабы», на которых могут заряжаться любые электромобили.",
                "Это самый востребованный вид зарядок для электрокаров в стране. В реализации проекта мы готовы взять на себя все этапы: от строительства и последующего обслуживания до GR.",
              ]}
            />
          </div>
          <div className={styles.cardRight}>
            <Card
              title="Консалтинг в энергетике"
              description="От правовых консультаций до разработки стратегии выхода на рынок электроэнергии"
              hoverInfo={[
                "– Полное, доступное, внятное и наглядное разъяснение законов, процессов и структуры рынка электроэнергии и мощности.",
                "– Индивидуальный подбор стратегии и инструментов для существенного сокращения издержек на электроэнергию.",
                "– Аутсорсинг службы главного энергетика.",
              ]}
            />
          </div>
        </div>
        <div className={styles.cols}>
          <div className={styles.colLeft}>
            <div className={styles.cardTop}>
              <Card
                title="Энергоснабжение"
                description="Поставляем электроэнергию и рассчитываем эффективность энергоснабжения"
                hoverInfo={[
                  "– Предоставляем варианты перехода на опт, услуги энерготрейдинга и плавный график авансовых платежей",
                  "– Увеличиваем необходимый объем э/э без дополнительной оплаты и согласовываем уменьшение договорных величин потребления ",
                  "– Возьмем на себя все работы по подготовке документов при заключении договора на энергоснабжение и по расторжению договора с действующим поставщиком",
                ]}
              />
            </div>
            <div className={styles.cardBottom}>
              <Card
                title="Энергосервис"
                description="Строительство, ремонт и обслуживание индивидуального инженерного проекта любой сложности"
                img={img2}
                hoverInfo={[
                  "Строительство, ремонт и обслуживание энергетической инфраструктуры.",
                  "– Подготовим специалистов в области энергосбережения (в том числе энергоаудиторов)",
                  "– Проведем полный комплекс работ в рамках энергоаудита",
                  "– Выполним работы в рамках подготовки и выполнения энергосервисных контрактов",
                ]}
              />
            </div>
          </div>
          <div className={styles.colRight}>
            <div className={styles.cardTop}>
              <Card
                title="Строительство АИС КУЭ "
                description="Строительство систем учета энергоресурсов под индивидуальные требования"
                img={img3}
                hoverInfo={[
                  "Наши специалисты перестроят существующую систему учета энергоресурсов и модернизируют ее в соответствии с требованиями предприятия, а также оптового или розничного рынка электроэнергии",
                ]}
              />
            </div>
            <div className={styles.cardBottom}>
              <Card
                title="Розничная генерация"
                description="Когенерация и производство энергии непосредственно в месте ее потребления"
                hoverInfo={[
                  "Энергоустановки удовлетворяют потребность в электрической и тепловой энергии не только объектов промышленности, но и энергодефицитных субъектов РФ.",
                  "Строительство, производство и обслуживание с целью бесперебойного и надежного питания, при соблюдении экономической составляющей затрат на электроэнергию.",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
