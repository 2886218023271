import styles from "./GroupOfCompanies.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "./styles.scss";
import "swiper/css/scrollbar";
import "swiper/css";

const GroupOfCompanies = () => {
  return (
    <div className={styles.groupOfCompanies} id="group">
      <div className={`${styles.title} text medium`}>Группа компаний</div>
      <div className={`${styles.groupOfCompanies__info} heading medium light`}>
        ГК Трансэнергопром занимается поставками электроэнергии на оптовом и
        розничном рынках, энергоаудитом, повышением энергоэффективности
        предприятий, строительством электрозаправочный инфраструктуры и
        развитием розничной генерации
      </div>
      <div className={styles.slider}>
        <Swiper
          spaceBetween={20}
          slidesPerView={4.1}
          scrollbar={{
            hide: true,
            draggable: true,
          }}
          breakpoints={{
            1536: {
              slidesPerView: 4.1,
              spaceBetween: 20,
            },
            1366: {
              slidesPerView: 3.6,
              spaceBetween: 20,
            },
            1136: {
              slidesPerView: 2.7,
              spaceBetween: 20,
            },
            962: {
              slidesPerView: 2.3,
              spaceBetween: 20,
            },
          }}
          modules={[Scrollbar]}
          className="groupOfCompaniesSwiper"
        >
          <SwiperSlide>
            <div className={styles.card}>
              <div className={`${styles.info} heading large`}>26</div>
              <div className={`${styles.description} text regular`}>
                Регионов России
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.card}>
              <div className={`${styles.info} heading large`}> &gt; 1000</div>
              <div className={`${styles.description} text regular`}>
                Cотрудников
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.card}>
              <div className={`${styles.info} heading large`}>
                6 <span className="text large">млрд кВт*ч</span>
              </div>
              <div className={`${styles.description} text regular`}>
                Eжегодный объем реализации электроэнергии
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.card}>
              <div className={`${styles.info} heading large`}>23</div>
              <div className={`${styles.description} text regular`}>
                Крупнейших промышленных предприятия
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.card}>
              <div className={`${styles.info} heading large`}>
                14 <span className="text large">лет</span>
              </div>
              <div className={`${styles.description} text regular`}>
                На рынке
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.card}>
              <div className={`${styles.info} heading large`}>
                1,2 <span className="text large">млн</span>
              </div>
              <div className={`${styles.description} text regular`}>
                Клиентов-физических лиц
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={styles.mobileCards}>
        <div className={styles.card}>
          <div className={`${styles.info} heading large`}>
            14 <span className="text large">лет</span>
          </div>
          <div className={`${styles.description} text regular`}>На рынке</div>
        </div>
        <div className={styles.card}>
          <div className={`${styles.info} heading large`}> &gt; 1000</div>
          <div className={`${styles.description} text regular`}>
            Cотрудников
          </div>
        </div>
        <div className={styles.card}>
          <div className={`${styles.info} heading large`}>
            6 <span className="text large">млрд кВт*ч</span>
          </div>
          <div className={`${styles.description} text regular`}>
            Eжегодный объем реализации электроэнергии
          </div>
        </div>
        <div className={styles.card}>
          <div className={`${styles.info} heading large`}>23</div>
          <div className={`${styles.description} text regular`}>
            Крупнейших промышленных предприятия
          </div>
        </div>
        <div className={styles.card}>
          <div className={`${styles.info} heading large`}>
            14 <span className="text large">лет</span>
          </div>
          <div className={`${styles.description} text regular`}>На рынке</div>
        </div>
        <div className={styles.card}>
          <div className={`${styles.info} heading large`}>
            1,2 <span className="text large">млн</span>
          </div>
          <div className={`${styles.description} text regular`}>
            Клиентов-физических лиц
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupOfCompanies;
