import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import youtube from "../../assets/svg/youtube.svg";
import telegram from "../../assets/svg/telegram.svg";

const Footer = () => {
  function awaitNewsApi(el) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: "auto", block: "start" });
    }, 500);
  }

  return (
    <div className={`${styles.footer} text regular`}>
      <div className={styles.col}>
        <div className={`${styles.contacts} text regular light`}>
          <div>
            <a href="tel:+74951034572">+7 (495) 103-45-72</a>
          </div>
          <div>E-mail: info@transeprom.ru</div>
          <div>Адрес: г. Москва, ул.Литвина-Седого д.4, стр.1</div>
        </div>
        <div className={styles.copyright}>
           © 2024 Компания ГК “Трансэнергопром”. Все права защищены 
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.item}>
          <HashLink smooth to="/#group">
            Группа компаний
          </HashLink>
        </div>
        <div className={styles.item}>
          <HashLink smooth to="/#partners">
            Наши партнеры
          </HashLink>
        </div>
        <div className={styles.item}>
          <HashLink smooth to="/#about">
            О компаниях
          </HashLink>
        </div>
        <div className={styles.item}>
          <HashLink to="/#solutions" scroll={(el) => awaitNewsApi(el)}>
            Решения для бизнеса
          </HashLink>
        </div>
        <div className={styles.item}>
          <Link to="/media">Медиа</Link>
        </div>
        <div className={styles.mobileRow}>
          <div className={styles.socialNetworks}>
            <a
              href="https://www.youtube.com/channel/UCqa2PHKmbbZDfUR5lFWITjQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="" />
            </a>
            <a
              href="https://t.me/tepgroup"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={telegram} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.col}>
        <div className="text regular light">
          Присоединяйтесь к нам в соцсетях
        </div>
        <div className={styles.socialNetworks}>
          <a
            href="https://www.youtube.com/channel/UCqa2PHKmbbZDfUR5lFWITjQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} alt="" />
          </a>
          <a
            href="https://t.me/tepgroup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={telegram} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
